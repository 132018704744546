import React from "react";
import { graphql } from "gatsby";
import CleanLayout from "../layouts/Clean";
import { Intro, SEO, PostPreview, Subtitle } from "components";
import { getImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const richTextOptions = {
  renderNode: {
    // eslint-disable-next-line
    [BLOCKS.PARAGRAPH]: (node, children) => <Subtitle>{children}</Subtitle>,
  },
};

const Service = ({ data }) => {
  const {
    allContentfulService: {
      nodes: [service],
    },
  } = data;
  if (!service) return null;
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <div className="w-screen px-8 md:px-28">
        <Intro {...service} />
        <section className="mb-16">
          {renderRichText(service.content, richTextOptions)}
        </section>
      </div>
    </CleanLayout>
  );
};

export default Service;

export const blogListQuery = graphql`
  query getService($slug: String!) {
    allContentfulService(
      filter: { slug: { eq: $slug }, node_locale: { eq: "it" } }
    ) {
      nodes {
        title
        description
        content {
          raw
        }
      }
    }
  }
`;
